import React from 'react';

import { Layout } from '../../components/layouts';
import { SEO } from '../../components/common';
import {
  WaterproofMattressProtectorWarrantyHeroSection,
  WaterproofMattressProtectorWarrantyIntroSection,
  WaterproofMattressProtectorWarrantyContentSection,
} from '../../components/sections';

const WaterproofMattressProtectorWarranty: React.FC = () => {
  return (
    <Layout>
      <SEO title="Waterproof Mattress Protector" />
      <WaterproofMattressProtectorWarrantyHeroSection />
      <WaterproofMattressProtectorWarrantyIntroSection />
      <WaterproofMattressProtectorWarrantyContentSection />
    </Layout>
  );
};

export default WaterproofMattressProtectorWarranty;
